@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.main-container {
  height: 100%;
  display: flex;
}

.wrapper {
  text-align: center;
  margin: auto;
  padding: 20px;
}

.title {
  font-size: 24px;
  letter-spacing: 8px;
}

.logo-wrapper {
  margin-bottom: 60px;
}

.logo-wrapper img {
  min-width: 250px;
}

.mission {
  font-size: 20px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 40px;
}

.contact {
  font-size: 18px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 40px;
}

.social-icon {
  margin-left: 30px;
  margin-right: 30px;
}
.social-icon:first-of-type {
  margin-left: 0;
}
.social-icon:last-of-type {
  margin-right: 0;
}
.social-icon img {
  width: 22%;
  max-width: 130px;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
  height: 100%;
}

img {
  vertical-align: middle;
}

body {
  font: 16px/26px 'Open Sans', Helvetica, Helvetica Neue, Arial;
  height: 100%;
  background: #252e39; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #16181f,
    #252e39
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #efefef;
}

@media only screen and (min-width: 1140px) {
  /* ===============
  Maximal Width
 =============== */

  .wrapper {
    width: 1026px; /* 1140px - 10% for margins */
    margin: auto;
  }
}

/* ==========================================================================
 Helper classes
 ========================================================================== */

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

